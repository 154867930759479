import React, { createContext, useState } from "react";
import Axios from "axios";
import { message } from "antd";
import config from "../config";

export const SignUpContext = createContext({});

const ACCESS_TOKEN = localStorage.getItem("access_token");

export const SignUpProvider = (props) => {
  const [profileData, setProfileData] = useState({
    categories: [],
    membership: {
      name: "Sponsor",
      cost: 0,
    },
    social: {
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
    },
    referral: {
      firstname: "",
      middlename: "",
      lastname: "",
    },
    adImage: "",
    bannerStartDate: "",
    bannerData: { banner: false, adCost: 0 },
    skipBanner: false,
    page: "",
    banner: false,
    location: { formatted_address: "" },
    termAgreement: false,
    term2Agreement: false,
    cartTotal: 0,
  });

  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [step, setStep] = useState(localStorage.getItem("step") || 1);

  const saveStepData = async (stepValue) => {
    setLoading(true);
    let doc = {
      onBoarding: step,
    };
    try {
      await Axios.patch(
        `${config.USERS}/users`,
        {},
        {
          headers: {
            token: ACCESS_TOKEN,
            doc: encodeURIComponent(JSON.stringify(doc)),
          },
        }
      );
      setStep(step + 1);
      localStorage.setItem("step", Number(step) + 1);
      setLoading(false);
    } catch (errpr) {
      message.error("Something went wrong please try again");
      setLoading(false);
    }
  };

  const saveService = async () => {
    const service = parseServiceFromState();

    try {
      setLoading(true);
      // USER ENDPOINT
      await Axios.patch(
        `${config.USERS}/users`,
        {},
        {
          headers: {
            token: ACCESS_TOKEN,
            doc: encodeURIComponent(
              JSON.stringify({
                advertiserProfile: service,
              })
            ),
          },
        }
      );

      message.success("Your profile has been saved!");
      setLoading(false);
      saveStepData();
    } catch (error) {
      message.error(
        "Something went wrong, if you continue to get this message please contact support"
      );
      console.log("Error - ", error);

      setLoading(false);
    }
  };

  const saveCompletedProfile = async (type) => {
    setLoading(true);
    // let status = { ...this.state.stepStatus };
    // if (!type) {
    //   status.completed = true;
    // }
    let doc = {
      onBoarding: profileData,
    };
    const response = await Axios.patch(
      `${config.USERS}/users`,
      {},
      {
        headers: {
          token: ACCESS_TOKEN,
          doc: encodeURIComponent(JSON.stringify(doc)),
        },
      }
    );
    if (response.status === 200) {
      setLoading(false);
      localStorage.setItem("onboard", JSON.stringify(profileData));
      localStorage.setItem("step", 1);
    } else {
      message.error("Something went wrong please try again");
      setLoading(false);
    }
  };

  const parseServiceFromState = () => {
    let user_id = localStorage.getItem("userid");
    const {
      organization_name,
      coordinates,
      places_component,
      categories,
      amenities,
      description,
      licenses,
      referral,
      website,
      storeHours,
      logoImageUrl,
      bannerImageUrl,
      social,
      formatted_address,
    } = profileData;
    let emails = {
      primary: profileData.primaryEmail,
      alternativeEmail: profileData.alternativeEmail,
    };
    const contact = {
      emails,
      phone: profileData.phone,
      primaryPhone: profileData.primaryPhone,
    };

    const location = [
      {
        contact,
        tags: [],
        places_component,
        geo: {
          type: "Point",
          coordinates,
        },
        hours: storeHours,
        formatted_address,
      },
    ];

    const images = {
      logo: logoImageUrl,
      profile: bannerImageUrl,
    };
    const descriptors = {
      website,
      categories,
      amenities,
      description,
      social,
    };

    const service = {
      referral,
      contact,
      user_id,
      images,
      descriptors,
      organization_name,
      location,
      licenses,
    };
    return service;
  };

  const getInitialData = async () => {
    const { data } = await Axios.get(`${config.USERS}/users`, {
      cors: true,
      responseType: "json", // default
      headers: {
        token: ACCESS_TOKEN,
      },
    });
    if (data?.advertiserProfile) {
      const { descriptors, contact, images } = data.advertiserProfile;
      setProfileData((prev) => ({
        ...prev,
        organization_name: data.advertiserProfile?.organization_name || "",
        bannerImageUrl: images?.profile || "",
        logoImageUrl: images?.logo || "",
        categories: descriptors?.categories || [],
        primaryEmail: contact?.emails.primary || "",
        primaryPhone: contact?.primaryPhone || "",
        alternativeEmail: contact?.emails.alternativeEmail || "",
        phone: contact?.phone || "",
        website: descriptors?.website || "",
        description: descriptors?.description || "",
        referral: data?.advertiserProfile?.referral || "",
        social: { ...descriptors?.social } || "",
        formatted_address:
          data?.advertiserProfile?.location[0].formatted_address || "",
      }));
    }
  };

  const data = {
    isLoading,
    setLoading,
    getInitialData,
    profileData,
    setProfileData,
    step,
    setStep,
    disableNext,
    setDisableNext,
    saveService,
    saveStepData,
    showCompletedModal,
    setShowCompletedModal,
    saveCompletedProfile,
  };

  return (
    <SignUpContext.Provider value={data}>
      {props.children}
    </SignUpContext.Provider>
  );
};

export default SignUpProvider;
