import React, { useContext } from "react";
import { Divider, Col, Row } from "antd";
import { SignUpContext } from "../../../providers/SignupContext";

export default function InvoicePreviewReformed() {
  const { profileData } = useContext(SignUpContext);

  const calculateTotalBannerCost = () => {
    return (profileData.banners || []).reduce(
      (total, banner) => total + banner.adCost,
      0
    );
  };

  return (
    <div className="invoicePreview">
      <h3>Invoice Preview</h3>
      <Divider style={{ backgroundColor: "darkgrey" }} />
      <strong>Monthly Fees</strong>

      {/* <Row style={{ display: "flex", marginTop: "1em" }}>
        <Col span={18}>
          Subscription -{" "}
          {profileData.membership.name === "Premium"
            ? "Platinum"
            : profileData.membership.name === "Sponsor"
            ? "Silver"
            : "Free"}
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          ${profileData.membership.cost}
        </Col>
      </Row> */}

      {(profileData.banners || []).map((banner, index) => (
        <Row key={index} style={{ display: "flex", marginTop: "1em" }}>
          <Col span={18}>Banner - {`${banner.runTime} days`}</Col>
          <Col span={6} style={{ textAlign: "right" }}>
            ${banner.adCost}
          </Col>
        </Row>
      ))}

      <Divider style={{ backgroundColor: "darkgrey" }} />
      <Row style={{ display: "flex" }}>
        <Col span={18}>Total:</Col>
        <Col span={6} style={{ textAlign: "right" }}>
          ${calculateTotalBannerCost()}
        </Col>
      </Row>
    </div>
  );
}
