import React from "react";

const YouTubeVideo = () => {
  return (
    <div
      style={{
        height: "100%", width: "100%"
      }}
    >
      <iframe
        src="https://www.youtube.com/embed/CPU1P38nTkY"
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        style={{
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        title="youtube_video"
      />
    </div>
  );
};

export default YouTubeVideo;
