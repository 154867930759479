import React, {useState} from "react";
import {Input, Button, Form, Row, Col, } from 'antd';


const FilterComponent = ({onFilter, onClear}) => {
    const [filters, setFilters] = useState({
        state: "",
        district: ""
    });

    const [form] = Form.useForm();

    

const handleInputChange = (e) => {
    const {name, value} = e.target;
    setFilters({
        ...filters,
        [name]: value
    });
};


const handleSubmit = () => {
    onFilter(filters);
    console.log(filters)
}

const handleClear = () => {
    form.resetFields();
    setFilters({
        state: '',
        district: ''
    });
    onClear();
}



return (
    <Form form= {form} layout="inline">
      <Row gutter={16}>
        <Col>
          <Form.Item label="State">
            <Input
              name="state"
              value={filters.state}
              onChange={handleInputChange}
              placeholder="Enter value for State"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="District">
            <Input
              name="district"
              value={filters.district}
              onChange={handleInputChange}
              placeholder="Enter value for District"
            />
          </Form.Item>
        </Col>
        <Col>
          <Button type="primary" onClick={handleSubmit}>
            Filter
          </Button>
        </Col>
        <Col>
          <Button onClick={handleClear}>Clear</Button>
        </Col>
      </Row>
    </Form>
);

};
export default FilterComponent;

