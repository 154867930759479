import React, { Component } from "react";
import { message, Divider } from "antd";
import BannerCreation from "./BannerCreation";
import axios from "axios";
import config from "../../../config";
import moment from "moment";
import { top200LocationsId, imageSizes } from "../../../helpers";
import geolib from "geolib";

export class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      category: "service",
      activeTab: "",
      location: this.props.location,
      type: "",
      page: "",
      start_date: "",
      end_date: "",
      adImage: "",
      cost: 0,
      totalCost: 0.0,
      touched: false,
      ads: {},
      sponsorAds: {},
      premiumAds: {},
      ad: [],
      newAds: [],
      openCart: false,
      typeKey: "",
      bookedDates: [],

      startDate: new Date(),
      endDate: new Date(),
      endOpen: false,

      locationValue: 5,
      pageValue: 1,
      typeValue: 1,
      daysAmount: 1,
      // setPrice: false,

      imageSize: "Please Select Page and Type",
      showImage: false,

      places_component: {},
      formatted_address: "",

      logged: false,

      RATE: config.ADRATE,
      value: "",
      locationInput: this.props.location.formatted_address,
      locationPlaceholder: "Location",

      disableAdStart: "",
      disableAdEnd: "",
      serviceLocations: [],

      showNextDate: true,
      gettingDate: true,
      availableDate: "",
      showDateSlot: false,
      showUploader: false,
    };
  }

  saveAds = async (ads) => {
    const json = JSON.stringify(ads);
    const response = await axios.post(
      `${config.ADS}/ads`,
      {},
      {
        headers: {
          doc: encodeURI(json),
          token: localStorage.getItem("access_token"),
        },
      }
    );
    if (response.status === 200) {
      this.setState({
        newAds: [],
        openCart: false,
        touched: false,
      });
    }
  };

  // Setting Costs for each Ad and Total for all Ads in cart
  resetCostForForm = () => {
    this.setState({
      cost: 0,
      locationPlaceholder: "Location",
      touched: false,
      locationValue: 5,
      pageValue: 1,
      typeValue: 1,
      daysAmount: 1,
      adImage: "",
      endDate: new Date(),
      // setPrice: false,
      showImage: false,
    });
  };

  resetCostValue = () => {
    this.setState({
      typeKey: "",
    });
  };

  handleSubscriptionCost = async (e) => {
    //CHECK LOCATION DATE HERE
    await this.props.handleStandardInputChange(e);

    this.getCostForForm();
  };

  getCostForForm = () => {
    const s = this.state;

    this.setState({ touched: true });

    if (s.typeKey) {
      this.props.setAdType(s.typeKey, this.props.adCost);
      this.setState({
        cost: this.props.adCost,
      });
      return this.props.adCost;
    } else {
      this.setState({
        cost: 0,
      });
    }
  };

  // getValueFromForm = async (e) => {
  //   let data = JSON.parse(e.target.value);
  //   let typeKey = data.acro;
  //   let weight = data.weight;
  //   let label = data.type;
  //   let stateValue = label + "Value";
  //   let valueName = data.type;
  //   let runTime = data.runTime;
  //   let cost = data.cost;
  //   let page = data.page;
  //   let typeName = data.type;
  //   let availableDate = this.state.availableDate;
  //   // await this.checkDateOverlap(typeKey, runTime);
  //   let setBanner = {
  //     typeKey,
  //     weight,
  //     label,
  //     page,
  //     typeName,
  //     stateValue,
  //     valueName,
  //     runTime,
  //     cost,
  //     availableDate,
  //   };

  //   await this.props.setBannerData(setBanner);

  //   await this.setImageGuide(typeKey);
  //   this.setState(
  //     {
  //       showUploader: true,
  //       touched: true,
  //       typeKey: typeKey,
  //       [stateValue]: weight,
  //     },
  //     () => this.getCostForForm()
  //   );
  //   // if((typeKey)) this.getAdForDisable(typeKey)
  // };

  checkDateOverlap = async (type, days) => {
    let placeID = this.props?.locationSet
      ? this.props.newLocation?.places_component?.place_id
      : this.props.location?.places_component?.place_id;
    this.setState({
      gettingDate: false,
      showDateSlot: true,
      showUploader: false,
    });
    try {
      let doc = {
        type: type,
        days: days,
        placeId: placeID,
      };

      const response = await axios.get(`${config.LOCATION}/checkdate`, {
        headers: {
          doc: JSON.stringify(doc),
        },
      });

      if (response.status === 200) {
        let dateObj = new Date(response.data.date).setHours(0, 0, 0, 0);
        this.setState({
          availableDate: `${moment(new Date(dateObj)).format(
            "dddd, MMMM Do YYYY"
          )}`,
          showNextDate: response.data.available,
          gettingDate: true,
        });
        this.props.getBannerDate(new Date(dateObj));
      }
    } catch (err) {
      this.setState({ gettingDate: true });
      message.error(
        "Something went wrong checking the avaliabilty for you ad, please contact support"
      );
    }
  };

  setImageGuide = (type) => {
    if (type in imageSizes) {
      this.setState({
        imageSize: `Recommended image size ${imageSizes[type]["w"]} x ${imageSizes[type]["h"]}`,
      });
    } else {
      this.setState({ imageSize: "Please Select Page and Type" });
    }
  };

  locationChange = (sentValue) => {
    const place = this.autocomplete.getPlace();
    if (place) {
      let lng = place.geometry.location.lng();
      let lat = place.geometry.location.lat();
      this.setState(
        {
          touched: true,
          locationPlaceholder: place.formatted_address,
          location: {
            places_component: {
              id: place.id,
              components: place.address_components,
              place_id: place.place_id,
            },
            formatted_address: place.formatted_address,
            geo: {
              type: "Point",
              coordinates: {
                lng: lng,
                lat: lat,
              },
            },
          },
        },
        () => {
          this.setLocationValue(this.state.location);
        }
      );
    }
    if (sentValue) {
      let lng = sentValue.geo.coordinates[0];
      let lat = sentValue.geo.coordinates[1];
      this.setState(
        {
          touched: true,
          locationPlaceholder: sentValue.formatted_address,
          location: {
            places_component: {
              id: sentValue.id,
              components: sentValue.address_components,
              place_id: sentValue.place_id,
            },
            formatted_address: sentValue.formatted_address,
            geo: {
              type: "Point",
              coordinates: {
                lng: lng,
                lat: lat,
              },
            },
          },
        },
        () => {
          this.setLocationValue(this.state.location);
        }
      );
    }
  };

  setLocationValue = (location) => {
    this.props.handleBannerLocation(location);
    this.setState({ locationInput: location.formatted_address }, () => {
      this.geocodePlaceId();
    });
  };

  checkLocation = (results, status, multi) => {
    try {
      let newPlaceId = multi
        ? results.places_component.id
        : this.state.location.places_component.place_id;

      if (!multi && newPlaceId) {
        let USstate = Object.keys(results[0].address_components).map(
          (place) => {
            return results[0].address_components[place].short_name;
          }
        );
        let lat = results[0].geometry.location.lat();
        let lng = results[0].geometry.location.lng();
        let locationFind = top200LocationsId.filter(
          (location) => newPlaceId === location.placeId
        );
        if (locationFind.length === 1) {
          this.setState(
            { locationValue: locationFind[0].weight, RATE: config.METROADRATE },
            () => {
              this.getCostForForm();
            }
          );
          if (multi) {
            return {
              locationValue: locationFind[0].weight,
              RATE: config.METROADRATE,
            };
          }
        }
        if (locationFind.length === 0) {
          this.checkMetroArea(USstate, lng, lat);
        }
      }
      if (multi && newPlaceId) {
        let USstate = Object.keys(results.places_component.components).map(
          (place) => {
            return results.places_component.components[place].short_name;
          }
        );
        let lat = results.geo[0];
        let lng = results.geo[1];
        let locationFind = top200LocationsId.filter(
          (location) => newPlaceId === location.placeId
        );
        if (locationFind.length === 1) {
          this.setState(
            { locationValue: locationFind[0].weight, RATE: config.METROADRATE },
            () => {
              this.getCostForForm();
            }
          );
          if (multi) {
            return {
              locationValue: locationFind[0].weight,
              RATE: config.METROADRATE,
            };
          }
          console.log(this.state.locationValue);
        }
        if (locationFind.length === 0) {
          this.checkMetroArea(USstate, lng, lat);
        }
      }
    } catch (error) {
      message.error("Something went wrong erroring location!");
    }
  };

  checkMetroArea = (USstate, lng, lat) => {
    let matchingState = [];

    top200LocationsId.forEach((location) => {
      USstate.forEach((st) => {
        if (location.USstate === st) {
          matchingState.push(location);
        }
      });
    });
    if (matchingState.length >= 1) {
      this.checkCityLimits(matchingState, lng, lat);
    }
  };

  checkCityLimits = (matches, lng, lat) => {
    //Could be recfatored, this was the best method I could figure out currently
    // If the USstate matches mutiple cities each city will be checked
    //if no true values are counted then the location is not in metro limits
    // Location value/weight for locations not in top200 need to be decided
    let count = 0;
    matches.forEach((location) => {
      let areaCheck = geolib.isPointInside(
        { latitude: lat, longitude: lng },
        location.areaCoords
      );

      if (areaCheck === true) {
        this.setState(
          { locationValue: location.weight, RATE: config.METROADRATE },
          () => {
            this.getCostForForm();
          }
        );
        count++;
      }

      if (count <= 0) {
        this.setState({ locationValue: 5, RATE: config.ADRATE }, () => {
          this.getCostForForm();
        });
      }
    });
  };

  geocodePlaceId = async (geocoder, map, infowindow) => {
    var geocode = new window.google.maps.Geocoder();
    let newPlaceId = this.state.location?.places_component?.place_id;
    geocode.geocode({ placeId: newPlaceId }, this.checkLocation);
  };

  render() {
    let bannerData = this.props.stepStatus.bannerData
      ? this.props.stepStatus.bannerData
      : null;
    return (
      <div>
        {this.props.banner ? (
          <div>
            <p>
              You have already created a banner for this step, if you are happy
              with your banner please press next.
            </p>
            Would you like to remove your banner?
            {/* eslint-disable-next-line */}
            <a
              style={{ marginLeft: "1em" }}
              onClick={this.props.removeBannerData}
            >
              Remove Banner
            </a>
            <br />
            <br />
            <strong>
              Location: {bannerData.location.formatted_address}
              <br />
              Page: {bannerData.page}
              <br />
              Type: {bannerData.typeName}
              <br />
              Run Time:{" "}
              {typeof this.props.runTime === "number"
                ? `${this.props.runTime} days`
                : this.props.runTime}
              <div style={{ margin: "2em 0 0 0", width: "90%", height: "90%" }}>
                <img
                  src={this.props.stepStatus.bannerData.image}
                  alt="preview"
                />{" "}
              </div>
            </strong>
          </div>
        ) : (
          <BannerCreation
          // currentMembership={this.props.currentMembership}
          // RATE={this.state.RATE}
          // adImage={this.props.adImage}
          // handleImageUpload={this.props.handleImageUpload}
          // saveAds={this.saveAds}
          // ads={this.state.sponsorAds}
          // cost={this.state.cost}
          // handleSubscriptionCost={this.handleSubscriptionCost}
          // typeKey={this.state.typeKey}
          // form={this.props.form}
          // page={this.props.page}
          // locationValue={this.state.locationValue}
          // geocodePlaceId={this.geocodePlaceId}
          // getCostForForm={this.getCostForForm}
          // gettingDate={this.state.gettingDate}
          // resetCostForForm={this.resetCostForForm}
          // initGooglePlaces={this.initGooglePlaces}
          // locationChange={this.locationChange}
          // handleBannerLocation={this.props.handleBannerLocation}
          // location={this.props.location}
          // availableDate={this.state.availableDate}
          // locationInput={this.state.locationInput}
          // setLocationValue={this.setLocationValue}
          // locationPlaceholder={this.state.locationPlaceholder}
          // touched={this.state.touched}
          // newAds={this.state.newAds}
          // totalCost={this.state.totalCost}
          // resetCostValue={this.resetCostValue}
          // showDateSlot={this.state.showDateSlot}
          // imageSize={this.state.imageSize}
          // showImage={this.state.showImage}
          // locations={this.state.serviceLocations}

          // locationLieu={locationLieu}
          />
        )}
        <Divider />
      </div>
    );
  }
}

export default Banners;
