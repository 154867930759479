import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tag, Empty, Divider, Row, Col, message, Button } from "antd";
import axios from "axios";
import Slider from "react-slick";

import FeatureServiceCards from "../services/FeatureServiceCard";
import NoCapManifest from "../../nocap.json";
import config from "../../config";
import CandidatesList from "../candidate/CandidatesList";
import Loading from "../Loading";
import Elections from "../elections/Elections";
import CustomTabs from "../shared/tabs";
import { useLoading } from "../../hooks";
import { sliderSettings } from "../../utils/sliderSettings";

const { TabPane } = Tabs;
const ACTIVE_HOME_TAB = "ACTIVE_HOME_TAB";
const { useBreakpoint } = Grid;

function HomeNavigation(props) {
  const [currentServiceCategory, setCurrentServiceCategory] = useState("");
  const [services, setServices] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem(ACTIVE_HOME_TAB) || "1"
  );
  const { isLoading, setIsLoading } = useLoading();
  const screens = useBreakpoint();
  const isMobile = screens.xs;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchServices(props.userLocation.lat, props.userLocation.lng);
    // eslint-disable-next-line
  }, []);

  const fetchServices = async (lat, lng) => {
    try {
      setIsLoading(true);
      const queryParameters = `search-text&lng=${lng}&lat=${lat}&limit=200`;
      const response = await axios.get(
        `${config.SERVICES}/services/search?${queryParameters}`
      );
      const responseServices = response.data;
      setServices(responseServices);
      setIsLoading(false);
    } catch (err) {
      message.error("Something is wrong with your location");
      setIsLoading(false);
    }
  };

  const changeTab = (key) => {
    localStorage.setItem(ACTIVE_HOME_TAB, key);

    setCurrentTab(key);

    const initialCategory = [
      "Coalition - A-D",
      "Organization - A-D",
      "Movement - A-D",
      "Service - A-D",
    ];

    if (key !== "2") {
      if (key === "5") {
        setCurrentServiceCategory("Service - Associations");
      } else {
        setCurrentServiceCategory(initialCategory[key - 2]);
      }
    }
  };

  const changeCategory = (e, value) => {
    setCurrentServiceCategory(value);
  };


  const serviceArray = services;
  const filterServices =
    serviceArray.services &&
    serviceArray.services.filter((s) =>
      s.descriptors.categories.includes(currentServiceCategory)
    );

  const bizCards =
    serviceArray.services &&
    filterServices.map((service, index) => {
      return (
        <Col
          key={index}
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={6}
          style={{
            marginBottom: 16,
          }}
        >
          <FeatureServiceCards service={service} key={service._id} />
        </Col>
      );
    });

  const settings = {
    ...sliderSettings,
    slidesToShow: 1,
  };

  const servicesCards = isMobile ? (
    <Slider {...settings}>{bizCards}</Slider>
  ) : (
    <Row justify="start" gutter={16}>
      {bizCards}
    </Row>
  );

  const tutorialTabList = [
    "tutorial-nav-elections",
    "tutorial-nav-candidates",
    "tutorial-nav-coalitions",
    "tutorial-nav-organizations",
    "tutorial-nav-movements",
    "tutorial-nav-services",
  ];

  // Add classname for tutorial
  useEffect(() => {
    document
      .querySelectorAll(".homepage-container .ant-tabs-nav")
      .forEach((el) => {
        el.classList.add("tutorial-navigation");
      });

    document
      .querySelectorAll(".homepage-container .ant-tabs-tab")
      .forEach((el, idx) => {
        el.classList.add(tutorialTabList[idx]);
      });
  }, []); // eslint-disable-line

  return (
    <div>
      <div className="homepage-container">
        <CustomTabs
          className="HomeNavigationTabs"
          defaultActiveKey="1"
          size="large"
          centered
          onChange={changeTab}
          activeKey={currentTab}
        >
          <TabPane tab="Elections" key="1" className="home-tab tutorial-body">
            <Elections userLocation = {props.userLocation} coordinates = {props.coordinates} />
          </TabPane>
          <TabPane tab="Politicians" key="2" className="home-tab"></TabPane>
          <TabPane tab="Coalitions" key="3" className="home-tab">
            {NoCapManifest.services.coalitionCategories.map((c, i) => (
              <Tag
                key={c}
                value={c}
                onClick={(e) => changeCategory(e, c)}
                className={
                  c === currentServiceCategory
                    ? "home-tab-tag-selected"
                    : "home-tab-tag"
                }
              >
                {c.substring("Coalition -".length)}
              </Tag>
            ))}
          </TabPane>
          <TabPane tab="Organizations" key="4" className="home-tab">
            {NoCapManifest.services.organizationCategories.map((c, i) => (
              <Tag
                key={c}
                value={c}
                onClick={(e) => changeCategory(e, c)}
                className={
                  c === currentServiceCategory
                    ? "home-tab-tag-selected"
                    : "home-tab-tag"
                }
              >
                {c.substring("Organization -".length)}
              </Tag>
            ))}
          </TabPane>
          <TabPane tab="Movements" key="5" className="home-tab">
            {NoCapManifest.services.movementCategories.map((c, i) => (
              <Tag
                key={c}
                value={c}
                onClick={(e) => changeCategory(e, c)}
                className={
                  c === currentServiceCategory
                    ? "home-tab-tag-selected"
                    : "home-tab-tag"
                }
              >
                {c.substring("Movement -".length)}
              </Tag>
            ))}
          </TabPane>
          <TabPane tab="Services" key="6" className="home-tab tab-services">
            {NoCapManifest.services.serviceCategories.map((c, i) => (

              <Tag
                key={c}
                value={c}
                onClick={(e) => changeCategory(e, c)}
                className={
                  c === currentServiceCategory
                    ? "home-tab-tag-selected"
                    : "home-tab-tag"
                }
              >
                {c.substring("Service -".length)}
              </Tag>


            ))}
          </TabPane>
        </CustomTabs>
        {isLoading && <Loading />}
        {currentTab === "1" ? (
          ""
        ) : currentTab === "2" ? (
          <CandidatesList divisions={props.divisions} />
        ) : bizCards && bizCards.length !== 0 ? (
          servicesCards
        ) : (
          <div style={{ padding: "5rem 0" }}>
            <Empty
              description={
                <span>Please choose a category from above</span>
              }
            />
            <Divider />
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeNavigation;
