import React, { useState, useEffect, useContext } from "react";
import { message } from "antd";
import { LoadingContext } from "../../../providers/LoadingContext";

const DoubleHalfBanner = (props) => {
  const config = "https://s3.amazonaws.com/dev.nocap.com";

  const FetchInfo = () => {
    const [bannerLeft, setBannerLeft] = useState(null);
    const [bannerRight, setBannerRight] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    async function get() {
      try {
        const left = await props.getAd("double-half-banner-left", props.page);
        setBannerLeft(left);
        const right = await props.getAd("double-half-banner-right", props.page);
        setBannerRight(right);
        console.log(left)
        console.log(right)
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
      get();
    }, []);
    return [bannerLeft, bannerRight, error, loading];
  };

  const { setGlobalLoading } = useContext(LoadingContext);

  const [bannerLeft, bannerRight, error, loading] = FetchInfo();

  setGlobalLoading(loading);

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this search"
    );

  return (
    <div className="double-half-banner">
      <div className="double-half-container">
        <img
          className="item-1"
          alt="banner left"
          src={`${config}${bannerLeft}`}
        />
      </div>
      <div className="double-half-container">
        <img
          className="item-2"
          alt="banner right"
          src={`${config}${bannerRight}`}
        />
      </div>
      <div className="double-half-container">
        <img
          className="item-3"
          alt="banner right"
          src={`${config}${bannerLeft}`}
        />
      </div>
    </div>
  );
};

export default DoubleHalfBanner;
