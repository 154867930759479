import React, { useContext } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Form, Select, Switch } from "antd";

import RateCard from "./RateCard";
import { pages } from "../../../helpers";
import useGuideData from "../../../hooks/useGuideData";
import LocationInput from "../../forms/LocationInput";
import { SignUpContext } from "../../../providers/SignupContext";
import useBannerCalculation from "./useBannerCalculation";
import Loading from "../../Loading"
import SingleImageUploader from "../imageUploaders/singleImage/SingleImageUploader";


const Option = Select.Option;

function BannerCreation(props) {
  const { profileData, setProfileData, setDisableNext } =
    useContext(SignUpContext);

  const { state, setState } = useBannerCalculation(props);

  const handleSkipBanner = (e) => {
    setProfileData((prev) => ({
      ...prev,
      skipBanner: e,
    }));

    setDisableNext(false);
  };

  const handleLocationChange = (value) => {
    setState((prev) => ({
      ...prev,
      selectedLocation: { formatted_address: value },
      enableBannerDropdown: false,
    }));
  };

  const handlePageChange = async (e) => {
    var page =
      pages[
        pages
          .map(function (item) {
            return item.name;
          })
          .indexOf(e)
      ];

    setState((prev) => ({ ...prev, pageValue: page.weight }));
    createRateCard(page.name);
  };

  const createRateCard = (e) => {
    if (e === "Home") {
      setState((prev) => ({
        ...prev,
        rateCard: [
          { type: "Banner Right", weight: 10, acro: "HHR" },
          { type: "Banner Top Left", weight: 6, acro: "HTL" },
          { type: "Banner Bottom Left", weight: 5, acro: "HBL" },
          { type: "Banner Bottom Mid", weight: 5, acro: "HBR" },
        ],
      }));
    }
    if (e === "Explore") {
      setState((prev) => ({
        ...prev,
        rateCard: [
          { type: "Half Banner Right", weight: 3, acro: "EHR" },
          { type: "Half Banner Left", weight: 3, acro: "EHL" },
        ],
      }));
    }
    if (e === "Calendar") {
      setState((prev) => ({
        ...prev,
        rateCard: [
          { type: "Half Banner Right", weight: 4, acro: "CHR" },
          { type: "Half Banner Left", weight: 4, acro: "CHL" },
        ],
      }));
    }
    if (e === "Then - Now") {
      setState((prev) => ({
        ...prev,
        rateCard: [
          { type: "Half Banner Right", weight: 3, acro: "BHR" },
          { type: "Half Banner Left", weight: 3, acro: "BHL" },
        ],
      }));
    }
    if (e === "Search") {
      setState((prev) => ({
        ...prev,
        rateCard: [
          {
            type: "Banner Skyscraper Top",
            weight: 5.9,
            acro: "SHT",
            page: state.pageName,
          },
          {
            type: "Banner Skyscraper Bottom",
            weight: 5.9,
            acro: "SHB",
            page: state.pageName,
          },
        ],
      }));
    }
  };

  const tutorialSteps = [
    // tutorial-banner tutorial-form
    {
      target: ".tutorial-location",
      content: "Add the location where you want your ad to be placed.",
      title: "Plans",
      disableBeacon: true,
    },
    {
      target: ".tutorial-page",
      title: "Plans",
      content: "Select on what page your ad will be placed.",
    },
  ];

  const { updateUserGuide, runJoyRideStep } = useGuideData();
  function handleJoyrideCallback(data) {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      updateUserGuide(3);
    }
  }

  return (
    <div className="BannerCreation">
      <Joyride
        run={!runJoyRideStep(3)}
        steps={tutorialSteps}
        showProgress
        showSkipButton
        continuous
        callback={handleJoyrideCallback}
      />

      <div style={{ marginBottom: "2em" }}>
        Complete this page if you are ready to place a banner advertisement on
        No Cap.
        <br />
        <br />
        Otherwise, if you want to do this later or have complex requirments
        (i.e., Banner advertisements in mutiple locations or specific date
        ranges), click "Skip this step" and you can create banners at a later
        time under the advertisement tab from your account page.
        <br />
        <br />
        <strong>Skip this step</strong>
        <Switch
          style={{
            marginLeft: 8,
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={handleSkipBanner}
          checked={profileData.skipBanner}
        />
      </div>

      {profileData.membership.name === "Free" ? (
        <div style={{ fontSize: "16px", fontWeight: "600" }}>
          {" "}
          If you are seeing this message you have chosen to continue with a Free
          membership. Banner creation is only available to Platinum or Silver
          members.
          <br />
          <br />
          To continue confirm "yes" to "Skip this step" and press the next
          button.
        </div>
      ) : (
        <Form
          ref={props.form}
          layout="vertical"
          // initialValues={{ location: props.locationInput }}
        >
          <Form.Item
            label="Location"
            name="location"
            rules={[{ required: true }]}
            className="tutorial-location"
          >
            <LocationInput
              id="places-autocomplete"
              onChange={handleLocationChange}
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              style={{ width: "100%" }}
              label="Page"
              name="page_choice"
              rules={[{ required: true }]}
              className="tutorial-page"
            >
              <Select
                placeholder="Page"
                onChange={handlePageChange}
                style={{ width: "100%" }}
                // disabled={state.enableBannerDropdown}
              >
                {pages.map((c) => (
                  <Option
                    style={{ width: "100%" }}
                    label="page"
                    key={c.name}
                    value={c.name}
                    weight={c.weight}
                  >
                    {c.name.replace(/\b\w/g, (l) => l.toUpperCase())}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <RateCard
            rates={state.rateCard}
            RATE={state.RATE}
            locationValue={state.locationValue}
            pageValue={state.pageValue}
            enablePreview={profileData.bannerData.adCost > 0}
          />

          {/* {props.showDateSlot ? (
          <div>
            {props.gettingDate ? (
              <div>
                {state.showNextDate ? (
                  <div>
                    <br />
                    There are currently no advertisments running in this spot,
                    your ad will go live <strong>{props.availableDate}</strong>
                  </div>
                ) : (
                  <div>
                    <br />
                    There is currently an advertisments running in this spot,
                    your ad can go live <strong>{props.availableDate}</strong>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                Checking avliability{" "}
                <Loading style={{ fontSize: `12px` }} />
              </div>
            )}
          </div>
          ) : null} */}

          {/* {props.showUploader ? (
            <Form.Item style={{ marginTop: "1em" }}>
              <div>
                <SingleImageUploader
                  handleImageUpload={(url) => props.handleImageUpload(url)}
                  imagePreviewUrl={props.adImage}
                  typeKey={props.typeKey}
                  title=""
                  layout={"adBanner"}
                />
              </div>
            </Form.Item>
          ) : null} */}
        </Form>
      )}
    </div>
  );
}

export default BannerCreation;
