import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../config";
import SharedManifest from "../../sharedManifest.json";
import ImageUploaderModal from "./components/SingleImageUploaderModal";
import LogoLayout from "./layouts/LogoLayout";
import BannerLayout from "./layouts/BannerLayout";
import AdBannerLayout from "./layouts/AdBannerLayout";
import NewsCoverLayout from "./layouts/NewsCoverLayout";
import { message } from "antd";

function SingleImageUploader(props) {
  const [imageUploaderModalVisibility, setImageUploaderModalVisibility] =
    useState(false);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [image, setImage] = useState({
    width: "",
    height: "",
    className: "",
    recommandedPhrase: "",
    endpoint: "",
    cropRatio: 1 / 1,
  });

  useEffect(() => {
    setTypeKey();
  }, []); // eslint-disable-line

  const setTypeKey = () => {
    let type = SharedManifest.imageDetails[props.typeKey];
    setImage({
      width: type.w,
      height: type.h,
      className: type.className,
      recommandedPhrase: type.phrase,
      endpoint: type.endpoint,
      cropRatio: type.w / type.h,
    });
  };

  const toggleVisibility = () => {
    setImageUploaderModalVisibility(!imageUploaderModalVisibility);
  };

  const handleRemoveImage = async () => {
    props.handleImageUpload("");
  };

  const uploadImage = async (data) => {
    message.info("Uploading image", 1);
    setUploadImageLoading(true);
    const body = JSON.stringify({ image: data });
    const response = await axios.post(
      `${config.IMAGES_UPLOAD}/images/${image.endpoint}`,
      body,
      {
        headers: {
          token: localStorage.getItem("access_token"),
          width: image.width,
          height: image.height,
        },
      }
    );
    if (response.status === 200) {
      props.handleImageUpload(config.IMAGE_HOST + "/" + response.data.key);
      setImageUploaderModalVisibility(false);
      setUploadImageLoading(false);
      return response.data && response.data.key;
    }
    setUploadImageLoading(false);
  };

  let $imagePreview;
  if (props.imagePreviewUrl) {
    $imagePreview = <img src={props.imagePreviewUrl} alt="preview" />;
  } else {
    $imagePreview = "";
  }

  let layout;
  switch (props.layout) {
    case "logo":
      layout = (
        <LogoLayout
          $imagePreview={$imagePreview}
          title={props.title}
          imagePreviewUrl={props.imagePreviewUrl}
          toggleVisibility={toggleVisibility}
          handleRemoveImage={handleRemoveImage}
        />
      );
      break;
    case "adBanner":
      layout = (
        <AdBannerLayout
          $imagePreview={$imagePreview}
          title={props.title}
          imagePreviewUrl={props.imagePreviewUrl}
          toggleVisibility={toggleVisibility}
          handleRemoveImage={handleRemoveImage}
        />
      );
      break;
    case "newsCover":
      layout = (
        <NewsCoverLayout
          $imagePreview={$imagePreview}
          title={props.title}
          imagePreviewUrl={props.imagePreviewUrl}
          toggleVisibility={toggleVisibility}
          handleRemoveImage={handleRemoveImage}
        />
      );
      break;
    default:
      layout = (
        <BannerLayout
          $imagePreview={$imagePreview}
          imagePreviewUrl={props.imagePreviewUrl}
          toggleVisibility={toggleVisibility}
          handleRemoveImage={handleRemoveImage}
        />
      );
  }

  return (
    <div className={image.className}>
      <ImageUploaderModal
        uploadImage={uploadImage}
        visibility={imageUploaderModalVisibility}
        toggleVisibility={toggleVisibility}
        imageClass={image.className}
        typeKey={props.typeKey}
        recommendedPhrase={image.recommandedPhrase}
        cropRatio={image.cropRatio}
        loading={uploadImageLoading}
      />
      {layout}
    </div>
  );
}

export default SingleImageUploader;
