import { useState, useContext } from "react";
import moment from "moment";
import config from "../../../config";
import { SignUpContext } from "../../../providers/SignupContext";
import axios from "axios";
import { message } from "antd";

const useBannerCalculationReformed = () => {
  const { setProfileData, setLoading } = useContext(SignUpContext);

  const [state, setState] = useState({
    loading: false,
    ads: {},
    ad: [],
    selectedLocation: { formatted_address: "" },
    startValue: "",
    endValue: "",
    rateCard: [],
    rateCardMulti: [],
    typeKey: "",
    placementType: "",
    value: "",
    allLocationSelection: [],
    multiLocationCart: [],
    adsType: { type: "Select Page", weight: 1, key: "HHR" },
    showImageUploader: false,
    enableBannerDropdown: true,
    showNextDate: true,
    pageValue: 1,
    RATE: config.ADRATE,
    locationValue: 5,
    imageSize: "Please Select Page and Type",
    startDate: null, 
    showNextDate: null,
    selectedImage: null
  });

  const getValueFromForm = (e) => {
    let data = JSON.parse(e.target.value);
    let typeKey = data.acro;
    let weight = data.weight;
    let label = data.type;
    let stateValue = label + "Value";
    let valueName = data.type;
    let runTime = data.runTime;
    let cost = data.cost;
    let page = data.page;
    let typeName = data.type;
    let availableDate = moment(new Date()).format("dddd, MMMM Do YYYY");
    // await this.checkDateOverlap(typeKey, runTime);
    let bannerValues = {
      typeKey,
      weight,
      label,
      page,
      typeName,
      stateValue,
      valueName,
      runTime,
      cost,
      availableDate,
    };

    const startDate = new Date().setHours(0, 0, 0, 0);

    setState((prev) => ({
      ...prev,
      adsType: {
        weight,
        type: label,
        key: typeKey,
      },
    }));

    setProfileData((prev) => ({
      ...prev,
      banners: [
        ...(prev.banners || []),
        {
          adType: bannerValues.typeKey,
          typeName: bannerValues.typeName,
          adCost: bannerValues.cost,
          page: state.pageValue,
          type: bannerValues.label,
          start_date: startDate,
          runTime: bannerValues.runTime,
          image: state.selectedImage, // Ensure this is populated
          location: state.selectedLocation, // Ensure this is populated
        },
      ],
    }));
  };

  const setImage = (image) => {
    setState((prev) => ({
      ...prev,
      selectedImage: image,
    }));
  };

  const setStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const checkDateOverlap = async (typeKey, runTime) => {
    let placeID = state.selectedLocation.place_id;
    try {
      const response = await axios.get(`${config.LOCATION}/checkdate`, {
        headers: {
          doc: JSON.stringify({
            type: typeKey,
            days: runTime,
            placeId: placeID,
          }),
        },
      });

      if (response.status === 200) {
        let availableDate = new Date(response.data.date);
        let showNextDate = response.data.available;
        setState((prev) => ({
          ...prev,
          startDate: showNextDate ? availableDate : prev.startDate, // Update to next available date if necessary
          showNextDate,
        }));
        return showNextDate;
      }
    } catch (error) {
      message.error("Error checking date overlap.");
      return false;
    }
  };

  const resetState = () => {
    setState({
      loading: false,
      adsType: { type: "Select Page", weight: 1, key: "HHR" },
      pageValue: 1,
      RATE: config.ADRATE,
      locationValue: 5,
      cost: 0,
      selectedImage: null,
      selectedLocation: { formatted_address: "" },
      startDate: null,
      showNextDate: null,
    });
  };

  const saveAds = async (ads) => {
    try {
      setLoading(true);
      const json = JSON.stringify(ads);
      await axios.post(
        `${config.ADS}/ads`,
        {},
        {
          headers: {
            doc: encodeURI(json),
            token: localStorage.getItem("access_token"),
          },
        }
      );
    } catch (error) {
      message.error("Something went wrong please try again");
      setLoading(false);
    }
  };

  return {
    state,
    setState,
    getValueFromForm,
    saveAds,
    setImage,
    setStartDate,
    checkDateOverlap,
    getValueFromForm,
    resetState
    
  };
};

export default useBannerCalculationReformed;
