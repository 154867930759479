// Libraries
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// ComponentWillMount and ComponentWillReceiveProps UI Warnings are related to react-stack-grid npm packages

// Components
import AdHelper from "../shared/ad/AdHelper";
import Careers from "../shared/careers/CareerTab";
import CategoryTags from "../news/CategoryTags";
import Loading from "../Loading";
import NewsCard from "../news/NewsCard";
import AdInRow from "../ad/AdInRow";
import axios from "axios";

// Ant Design Components
import { Empty, message, BackTop, Button, Row, Col, Select} from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Helpers & Config
import NoCapManifest from "../../nocap.json";
import config from "../../config";
import useNewsData from "../../hooks/useNewsData";

import DynamicCityStateDistrict from "../forms/candidateForm/components/DynamicCityStateDistrict"

import FilterComponent from "../news/FilterComponent"

import Add from "../forms/candidateForm/components/office/Add";
import { capitalizeFirstLetterOfEachWord } from "../../helpers";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { event } from "react-ga";


const { Option } = Select;
let districtIndex = 1;

const NewsPage = (props) => {
  const [currentCategory, setCurrentCategory] = useState("");
  const [districts, setDistricts] = useState(NoCapManifest.candidateInfoForm.districts);
  const [districtSelections, setDistrictSelections] = useState(null);
  const [inputState, setInputState] = useState({
    state: "",
    division: "",
    district: ""
  });

  
  const [categorizedBlogItems, setCategorizedBlogItems] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [data, setData] = useState([])
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Retrieve window and news data/states using custom hook

  // const { get, data, error, loading } = useNewsData(
  //   `${config.BLOGS}/blogs/all`
  // );

  useEffect(() => {
    fetchData();
    console.log(props.userLocation)
  }, [currentCategory]);

  const handleChangeDistrict = async(value) => {
    setDistrictSelections(value)
    
    console.log(districtSelections)
  }


  const fetchData = async() => {
    try {
      const response = await axios(`${config.BLOGS}/blogs/all`)
      const fetchedData = response.data;
      console.log(fetchedData)
      const dataFilteredByCategory = fetchedData.filter(post => post.category === currentCategory);
      setLoading(true);
      setData(fetchedData);
      setFilteredData(dataFilteredByCategory);
    } catch(error) {
      setError(error)
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  console.log(data)
  console.log(filteredData)


  const addDistrict = (e) => {
    e.preventDefault();
    let i = districtIndex++;
    let addedDistrict = inputState.district
      ? capitalizeFirstLetterOfEachWord(inputState.district)
      : `New Area ${i}`;

    if(districts.includes(addedDistrict)) {
      console.log("District already exists")
      message.error("District already exists")
      setInputState({...inputState, district: ""})
    } else {
    setInputState({ ...inputState, district: "" });
    setDistricts([ ...districts, addedDistrict]);
    }

  }

  //filter blogs according to user selection of state, district, both or none

  const handleFilter = (filters) => {
    const {state, district} = filters;
    const filtered = data.filter(item => {
      const stateMatch = state? item.state?.includes(state): true;
      const districtMatch = district? item.district?.includes(district): true;
      const notArchived = !item.archived;
      const categoryMatch = currentCategory? item.category.includes(currentCategory): true;
      console.log(item);
      console.log(stateMatch);
      console.log(districtMatch);
      console.log(notArchived);
      console.log(categoryMatch);
      return stateMatch && districtMatch && notArchived && categoryMatch;
    })
    console.log(filtered)
    setFilteredData(filtered);
  }

  const handleCategoryChange = (c) => {
    console.log(c)
    setCurrentCategory(c)
    handleFilterClear()
  }

  const handleFilterClear = () => {
    fetchData();
  }


  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information"
    );

  let result = null;
  if (loading) {
    result = <Loading />;
  } else if (data && data.length > 0) {
    let newsCount = data.length;
    let careerPosts = [];

    const user = JSON.parse(localStorage.getItem("user")) || {};

    

    let blogItems = filteredData.map((post, idx) => {
      if (post.archived !== true) {
        return (
          <AdInRow count={idx + 1} key={post["_id"]}>
            <Col
              xs= {24}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              style={{
                padding: "0 1em",
                marginBottom: "2em",
              }}
            >
              <NewsCard
                post={post}
                pathname={`/news/${post["_id"]}`}
                getData={fetchData}
              />
            </Col>
          </AdInRow>
        );
      } else {
        return "";
      }
    });

    // const filterBlogs =
    // serviceArray.services &&
    // serviceArray.services.filter((s) =>
    //   s.descriptors.categories.includes(currentServiceCategory)
    // );



    result = (
      <>
        <div className="news-cta-container">
          {/* Admin condition: only show btn if admin */}
          {user.role === "admin" && (
            <Link to="/news/new" className="news-cta--btn-container">
              <Button
                className="btn-bordered"
                icon={<PlusOutlined />}
                size="large"
              >
                Add News Post
              </Button>
            </Link>
          )}
          {/* Admin condition ends */}
        </div>

        {newsCount !== 0 ? (
          <Row className="news-row">{blogItems}</Row>
        ) : currentCategory === "Careers" ? (
          <Careers careerPosts={careerPosts} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        

        <BackTop />
      </>
    );
  } else {
    result = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <div>
      <div className="news-ads-container">
        <AdHelper page="news" />
      </div>
      <br/>
      <div className="news-cta-container">
        <CategoryTags
              categories={NoCapManifest.news.newsCategories}
              value={currentCategory}
              onChange={handleCategoryChange}
        />
      </div>
      <br/>
      <div>
        <FilterComponent onFilter={ handleFilter } onClear = { handleFilterClear } />
      </div>
      <br />
      {result}
      <br />
      <div className="news-ads-container">
        <AdHelper page="news" />
      </div>
    </div>
  );
};

export default NewsPage;
