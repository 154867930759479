import React, { useState, useEffect, useContext } from "react";
import { message } from "antd";
import { LoadingContext } from "../../providers/LoadingContext";

const VerticalBanner = (props) => {
  const config = "https://s3.amazonaws.com/dev.nocap.com";

  const FetchInfo = () => {
    const [verticalBannerUp, setVerticalBannerUp] = useState(null);
    const [verticalBannerDown, setVerticalBannerDown] = useState(null);
    const [error, setError] = useState(null);
    const { setGlobalLoading } = useContext(LoadingContext);

    async function get() {
      try {
        setGlobalLoading(true);
        const up = await props.getAd(
          `vertical-banner-${props.position}-up`,
          props.page
        );
        setVerticalBannerUp(up);
        const down = await props.getAd(
          `vertical-banner-${props.position}-down`,
          props.page
        );
        setVerticalBannerDown(down);
        console.log(verticalBannerUp);
        console.log(verticalBannerDown);
      } catch (e) {
        setError(e);
      } finally {
        setGlobalLoading(false);
      }
    }
    useEffect(() => {
      get();
    }, []); // eslint-disable-line
    return [verticalBannerUp, verticalBannerDown, error];
  };

  const [verticalBannerUp, verticalBannerDown, error] = FetchInfo();

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this search"
    );

  return (
    <div className="vertical-banner">
      <div className="vertical-banner-container">
        <img alt="vertical banner" src={`${config}${verticalBannerUp}`} />
      </div>
      <div className="vertical-banner-container">
        <img alt="vertical banner" src={`${config}${verticalBannerDown}`} />
      </div>
    </div>
  );
};

export default VerticalBanner;
