import React, { useEffect, useState } from "react";
import { BackTop, Form, Row, Col, Input, Radio, Checkbox, Button } from "antd";

const Advertise = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [form] = Form.useForm();

    const [adsFor, setAdsFor] = useState("");
    const [noOfIndustry, setNoOfIndustry] = useState("");

    const onFinish = (values) => {
        console.log(values)
    }

    const onChangeAdsFor = (e) => {
        console.log('radio checked', e.target.value);
        setAdsFor(e.target.value);
    }

    const onChangeIndustry = (e) => {
        console.log('radio checked', e.target.value);
        setNoOfIndustry(e.target.value);
    }

    const onChangeAgreement = (e) => {
        console.log(`checked = ${e.target.checked}`);
      };
  

    return (
      <div className="constrain">
        <div className="inner">
          <h2 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Thank you for your interest in advertising with us! We need a little more info.</h2>
          <p style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Please provide your information. Once complete, a representative will contact you within 24-48 hours.</p>

          <Form
            name="advertiseForm"
            className="accountForm"
            onFinish={onFinish}
            layout="vertical"
            form={form}
            style={{width: "80%"}}
          >

          <Row gutter={[12]}>
            <Col span={24}>
                <Form.Item
                    label="Current No Cap username"
                    name="username"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your username",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter username"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your first name",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter first name"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your last name",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter last name"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your address",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter address"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    label="City"
                    name="city"
                    rules={[
                    {
                        required: true,
                        message: "Please enter city ",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter city"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    label="State"
                    name="state"
                    rules={[
                    {
                        required: true,
                        message: "Please enter state",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter state"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    label="Zip Code"
                    name="zipCode"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your zip code",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter zip code"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="Office Number"
                    name="officeNumber"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your office number",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter office number"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your mobile number",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter mobile number"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your email",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter email"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>


            <Col span={24}>
                <Form.Item
                    label="Website URL(s)"
                    name="websiteURL"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your website url",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter website url"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="Social Media URL(s)"
                    name="socialMediaURL"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your current social media URL",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter current social media URL"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="Industry"
                    name="industry"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your current industry",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter current industry"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your current role",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter current role"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    label="How did you hear about us?"
                    name="aboutUs"
                    rules={[
                    {
                        required: true,
                        message: "Please enter your answer",
                    },
                    ]}
                    >
                        <Input
                        placeholder="enter response"
                        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
                        />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label="Ads For"
                    name="adsFor"
                    rules={[
                    {
                        required: true,
                        message: "Please select your response",
                    },
                    ]}
                    >
                        <Radio.Group onChange={onChangeAdsFor} value={adsFor}>
                            <Radio style={{fontWeight: "normal"}} value={1}>one location</Radio>
                            <Radio style={{fontWeight: "normal"}} value={2}>multi-locations</Radio>
                            <Radio style={{fontWeight: "normal"}} value={3}>multi-state</Radio>
                        </Radio.Group>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label="Are you affiliated with one or more industries?"
                    name="industries"
                    rules={[
                    {
                        required: true,
                        message: "Please select your response",
                    },
                    ]}
                    >
                        <Radio.Group onChange={onChangeIndustry} value={noOfIndustry}>
                            <Radio style={{fontWeight: "normal"}} value={1}>yes</Radio>
                            <Radio style={{fontWeight: "normal"}} value={2}>no</Radio>
                        </Radio.Group>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Checkbox onChange={onChangeAgreement} style={{fontWeight: "normal"}}>Yes, I agree to receive No Cap's newsletter for information on news, promotions, products & offers</Checkbox>  
            </Col>


            <Col
                span={24}
                style={{
                    textAlign: "center",
                    marginTop: "5%"
                }}
            >
            <Form.Item>
                <Button
                className="btn-bordered"
                htmlType="submit"
                style={{ margin: "0px 5px", width: "85px" }}
                type="text"
                >
                Submit
                </Button>
            </Form.Item>
            </Col>
          </Row>
        </Form>
          
        </div>
        <BackTop />
      </div>
    );
  };

export default Advertise;
