import React from "react";
import { Col } from "antd";

import { useBreakpoint } from "../../hooks/useWindowSize";
import AdHelper from "../shared/ad/AdHelper";

const AdInRow = ({ count, children }) => {
  const { mobileView, width } = useBreakpoint();
  const adRowCount = width >= 1024 ? 8 : !mobileView ? 4 : 2;
  let placeAd = count % adRowCount === 0;
  return (
    <>
      {children}
      {placeAd && (
        <Col
          md={24}
          style={{
            padding: "1em",
            marginTop: "2em"
          }}
        >
          <AdHelper page="news" />
        </Col>
      )}
    </>
  );
};

export default AdInRow;