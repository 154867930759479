import React from "react";
import { Table, Empty } from "antd";

const ContributionContent = (props) => {
  const contributions = props.details.contributions;
  const columns = [
    { title: "Industry", dataIndex: "industry", key: "industry", width: 100 },
    { title: "Name", dataIndex: "name", key: "name", width: 100 },
    { title: "Amount", dataIndex: "amount", key: "amount", width: 100 },
  ];

  return (
    <div className="contributions">
      {contributions !== null && contributions !== undefined ? (
        <div>
          {contributions.length !== 0 ? (
            <div>
              <Table
                columns={columns}
                dataSource={contributions}
                pagination={{ pageSize: 8 }}
              />
            </div>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"Candidate has not yet provided information."}
            />
          )}{" "}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Candidate has not yet provided information."}
        />
      )}
    </div>
  );
};
export default ContributionContent;
