import React, { useContext } from "react";
import { Row, Col } from "antd";

import OnboardingSteps, { StepButtons } from "./OnboardingSteps";
import InvoicePreviewReformed from "./InvoicePreviewReformed";
import DescribeService from "./DescribeService";
import SelectSubscription from "./SelectSubscription";
import Banners from "./Banners";
import ConfirmSubscription from "./ConfirmSubscription";
import FreeSubscriptionPayment from "./FreeSubscriptionPayment";
import StripeCheckout from "../stripe/StripeCheckoutOnlyInject";
import { SignUpContext } from "../../../providers/SignupContext";
import { LoadingContext } from "../../../providers/LoadingContext";
import BannersReformed from "./BannersReformed";

const Onboarding = () => {
  const { setGlobalLoading } = useContext(LoadingContext);
  const { profileData, setProfileData, step, getInitialData } =
    useContext(SignUpContext);
  const currentStep = step - 1;
  const bannerFormRef = React.createRef();

  React.useEffect(() => {
    setGlobalLoading(true);
    getInitialData().then(() => {
      setGlobalLoading(false);
    });
  }, []); // eslint-disable-line

  // const setBannerData = (values) => {
  //   setProfileData((prev) => ({
  //     ...prev,
  //     bannerData: {
  //       adType: values.typeKey,
  //       typeName: values.typeName,
  //       adCost: values.cost,
  //       page: this.state.page,
  //       type: values.label,
  //       start_date: this.state.bannerStartDate,
  //       runTime: values.runTime,
  //     },
  //   }));
  // };

  //new code for reformed advertisement
  const setBannerData = (values) => {
    setProfileData((prev) => ({
      ...prev,
      banners: [
        ...(prev.banners || []),
        {
          adType: values.typeKey,
          typeName: values.typeName,
          adCost: values.cost,
          page: values.page,
          type: values.label,
          start_date: values.start_date,
          runTime: values.runTime,
          image: values.image,
          location: values.location,
        },
      ],
    }));
  };

  const handleBannerLocation = (place) => {
    this.bannerFormRef.current.setFieldsValue({
      location: place.formatted_address,
    });
    setProfileData((prev) => ({
      ...prev,
      locationSet: true,

      newLocation: {
        places_component: place.places_component,
        formatted_address: place.formatted_address,
        coordinates: [place.geo.coordinates.lng, place.geo.coordinates.lat],
        geo: {
          type: "Point",
          coordinates: [place.geo.coordinates.lng, place.geo.coordinates.lat],
        },
      },
    }));
  };

  const setAdType = (adType, adCost) => {
    setProfileData((prev) => ({
      ...prev,
      bannerData: {
        ...prev.bannerData,
        adType: adType,
        adCost: adCost,
      },
    }));
  };

  const handleStandardInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageUpload = (url) => {
    setProfileData((prev) => ({
      ...prev,
      showImage: true,
      adImage: url,
    }));
  };

  // const removeBannerData = () => {
  //   setProfileData((prev) => ({
  //     ...prev,
  //     bannerData: {},
  //   }));
  // };

  //new code for reformed ads

  const removeBannerData = (index) => {
    setProfileData((prev) => ({
      ...prev,
      banners: prev.banners.filter((_, i) => i !== index),
    }));
  };

  const content = [
    {
      title: "Description",
      subtitle: "This information will be used for your public profile. ",
      heading: "Describe your Service",
      content: <DescribeService />,
    },
    // {
    //   title: "Plans",
    //   subtitle:
    //     "This plan is a monthly fee that will occur on the same day of the month, every month.",
    //   heading: "select Plan",
    //   content: <SelectSubscription />,
    // },
    {
      title: "Banners",
      subtitle: `Purchase your banner placement to "promoting" immediateley!`,
      heading: "Banner Advertisements",
      content: (
        <BannersReformed
          // setBannerData={setBannerData}
          // // bannerLocation={this.state.bannerLocation}
          // adImage={profileData.adImage}
          // handleBannerLocation={handleBannerLocation}
          // newLocation={profileData.newLocation}
          // locationSet={profileData.locationSet}
          // setAdType={setAdType}
          // form={bannerFormRef}
          // location={profileData.location}
          // currentMembership={profileData.membership}
          // typeKey={profileData.typeKey}
          // runTime={profileData.runTime}
          // handleStandardInputChange={handleStandardInputChange}
          // handleImageUpload={handleImageUpload}
          // page={profileData.page}
          // banner={profileData.banner}
          // stepStatus={{
          //   page: profileData?.bannerData?.page,
          //   typeName: profileData?.bannerData?.typeName,
          //   image: profileData?.bannerData?.image,
          // }}
          // removeBannerData={removeBannerData}
          // adCost={profileData.adCost}

          setBannerData={setBannerData}
          adImage={profileData.adImage}
          handleBannerLocation={handleBannerLocation}
          newLocation={profileData.newLocation}
          locationSet={profileData.locationSet}
          setAdType={setAdType}
          form={bannerFormRef}
          location={profileData.location}
          currentMembership={profileData.membership}
          typeKey={profileData.typeKey}
          runTime={profileData.runTime}
          handleStandardInputChange={handleStandardInputChange}
          handleImageUpload={handleImageUpload}
          page={profileData.page}
          profileData={profileData}  // Pass the entire profileData object
          banners = {profileData.banners}
          removeBanner={removeBannerData}
          adCost={profileData.adCost}
          stepStatus={{
            page: profileData?.bannerData?.page,
            typeName: profileData?.bannerData?.typeName,
            image: profileData?.bannerData?.image,
          }}
        />
      ),
    },
    {
      title: "Agreement",
      subtitle:
        "Please review the terms and conditions. If you agree to the terms and conditions, please check the boxes below.",
      heading: "Agree To Terms",
      content: <ConfirmSubscription />,
    },
    {
      title: "Payment",
      subtitle: "Enter your payment details to start your subscription ",
      heading: "Enter Payment Details",
      content:
        profileData.membership.name === "Free" ? (
          <FreeSubscriptionPayment />
        ) : (
          <StripeCheckout />
        ),
    },
  ];

  return (
    <div className="OnboardingContainer">
      <OnboardingSteps
        setProfileData={setProfileData}
        profileData={profileData}
      />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={18}>
          <h1 className="onboard-heading" style={{ textAlign: "center" }}>
            {content[currentStep].heading}
          </h1>
          <div style={{ textAlign: "center", fontStyle: "italic" }}>
            {content[currentStep].subtitle}{" "}
          </div>

          <div style={{ marginTop: "2em" }}>{content[currentStep].content}</div>
        </Col>
        <Col span={6} xs={24} sm={24} md={6}>
          <InvoicePreviewReformed
          totalCost={(profileData.banners || []).reduce(
            (total, banner) => total + banner.adCost,
            0
          )}
          // cartTotal={profileData.cartTotal}
          // membership={profileData.membership}
          // banner={profileData.banner}
          // bannerCost={profileData.bannerData.adCost}
          // runTime={30}
          />
        </Col>
        <Col xs={24} sm={24} md={18}>
          <StepButtons />
        </Col>
      </Row>
    </div>
  );
};

export default Onboarding;
